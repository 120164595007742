





































































.social-large{
  font-size: 48px;
}
